import React, { Component } from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import AOS from "aos"
import 'aos/dist/aos.css';
import newsArticleOne from "../../images/news/shuttleid-partnership.jpg"
import NewsStrip from "../../components/news-strip"

class NewsPage extends Component {

  componentDidMount(){
    AOS.init();
    window.addEventListener('load', AOS.refresh);
    // @NOTE: Hack to force AOS to work :(
    window.setTimeout(AOS.refresh, 1000);
  }

  componentDidUpdate() {
    AOS.refresh();
  }

  render() {
    return (
      <Layout>
        <SEO title="Tetley's Coaches partner with ShuttleID" description="After completing a successful trial of ShuttleID, Tetley's Coaches are pleased to be installing the equipment across their fleet that will be operating the school bus services serving Rossett, St John Fishers and St Aidan’s schools of Harrogate." />
        <div className="block-services inner-body-bg">
          <div className="body gutter page-content-parent page-article" style={{ background: 'white' }}>
            <div>
              <h1 className="page-content-heading">Tetley's Coaches partner with ShuttleID</h1>
              <p className="news-article-date">June 2019</p>
            </div>

            <div className="news-article-body">
              <p className="news-article-img">
                <img src={newsArticleOne} alt="" />
              </p>
              <p>
                After completing a successful trial of <a href="https://shuttleid.uk/">ShuttleID</a> (The e-ticketing platform for school transport) we are pleased to be installing the equipment across our fleet that will be operating the school bus services serving Rossett, St John Fishers and St Aidan’s schools of Harrogate.
              </p>
              <p>Tickets can now be purchased by following the simple steps via our <Link to="/school-services">website</Link>.</p>
              <p>Ian Tetley, Tetley’s Coaches’ MD, says: “The team at ShuttleID have listened to our concerns and developed a cost-effective product which should appeal to operators of school services.”</p>
              <p>Services begin September 2019.</p>
            </div>
          </div>
        </div>

        <NewsStrip />

      </Layout>
    );
  }
}

export default NewsPage
